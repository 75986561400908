<template>
  <div :class="containerClass">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'LibraryContainer',

  props: {},

  computed: {
    ...mapState(useWebsiteStore, ['theme']),

    containerClass() {
      if (this.theme === 'theme2') {
        return 'tw-z-[2000] tw-bg-[var(--white)]';
      } else if (this.theme === 'theme3') {
        return 'pxp-content main-body container';
      }
      return '';
    },
  },
});
</script>
